<template>
  <div class="component-tab-table-list">
    <el-tabs v-model="tabName" @tab-click="clickTab" tab-position="left">
      <el-tab-pane :label="tab.label" :name="tab.name" v-for="(tab,tabIndex) in tabTableList" style="width: 100%;">
        <div class="info">
          <span>总计:{{ tab.list.length }}</span>
        </div>
        <el-table :data="tab.list" v-loading="false"
                  element-loading-text="加载中"
                  height="400"
                  highlight-current-row
                  fit
                  style="width: 100%;">
          <template v-for="(item,index) in tab.listDefine">
            <el-table-column :label="item.label" align="center" :width="item.width?item.width:'120'">
              <template slot-scope="scope">
                <!--如果有格式化f函数-->
                <span v-if="item.f&&!item.html">{{ item.f(scope.row[item.id]) }}</span>
                <!--如果有格式化f函数且显示html为真-->
                <span v-if="item.f&&item.html" v-html="item.f(scope.row[item.id])"></span>
                <!--如果没有-->
                <span v-if="!item.f&&!item.html">{{ scope.row[item.id] }}</span>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
/**
 * 用el-tab分开的列表
 */
export default {
  name: "tabTableList",
  props: {
    tabTableList: {
      Type: Array,
      default: [],
      require: true,
    },
    tab: {
      Type: String,
      default: "",
      require: true,
    }
  },
  data() {
    return {
      tabName: this.tab,
    }
  },
  methods: {
    clickTab() {

    }
  }
}
</script>

<style scoped>

</style>
