import axios from "axios";
import {msg_err} from "@/utils/ele_component";
import {api_url, API_URL_EXP_CONSUMER} from '@/model/ConfigModel'
import {LoginModel} from "@/model/exp/LoginModel";

class ToolsModel {
    // 获取文本二维码
    static async getTextQrCode(url) {
        return new Promise((resolve, reject) => {
            axios.create({
                baseURL: API_URL_EXP_CONSUMER
            }).request({
                headers: {
                    'Content-Type': 'text/plain',
                    'Authorization': "Bearer " + LoginModel.getToken()
                },
                url: `consumer/tools/qrCodeByText`,
                data: {
                    content: url
                },
                method: 'post',
                responseType: 'blob'
            }).then(response => {
                // 返回的png格式,转成url
                let url = URL.createObjectURL(response.data)
                resolve(url)
            }).catch(error => {
                if (error.response && error.response.status === 403) {// 如果超过限制，弹窗显示错误信息
                    const blob = error.response.data;
                    if (blob.type === 'text/html') {
                        // 使用FileReader将Blob转换为字符串
                        const reader = new FileReader();
                        reader.onload = function (e) {
                            const text = e.target.result;
                            msg_err(text); // 打印转换后的字符串
                        };
                        reader.readAsText(blob);
                        console.error('获取验证码失败！', error.response.data);
                    }
                } else {
                    // 处理其他请求错误
                    console.error('获取验证码失败！', error);
                }
            });
        })
    }
}

export {ToolsModel}
